
    .salesProgramSplitRelated {
        display: none;
    }
    .historicSalesRelated {
        display: none;
    }

    .registrationRelated {
        display: none;
    }

    .EBSDashboard {
        display: none;
    }

    .controlisModerator,
    .controlsalesContact,
    .controlcontentContact {
        display: none;
    }

    .chIcashPaid {
        display: none;
    }

    .BDMDashboard {
        display: none;
    }
    
    .importContactButton {
        display: none;
    }

